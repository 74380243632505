import { searchUrl } from './url-config'
import {COLUMN_MAPPING} from './common/constants/CompanyFilterMapping';
import {sortOptionsByInputMatchIndex} from './utils'
import { CompanySearchableFields } from './common/constants/CompanySearchableFields';
import { RegencyType } from './common/types/companyListType';

var cache = {};
var company_lists = {};
const DEFAULT_SUGGESTION_COUNT = 30;
export async function SaveMap(map_id, name, description = '', rules, joid, status) {
  let body = {
    name: name,
    description: description,
    rules: rules,
    id: map_id,
    type: 'create_or_update',
    joid: joid
  }
  if(status !== undefined){
    body.status = status;
  }
  const response = await fetch(`//pub.findem.ai/pub/api/execmap${joid ? `?joid=${joid}` : ''}`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();
  console.log(data);
  return data;
}

function buildQuery(field, operator, value) {
  let prefix = ''
  switch (field) {
    case 'Company':
      prefix = "workexp.current.company.id:";
      if (operator == "Last 3 years") {
        prefix = "workexp.recent.company.id:"
      } else if (operator == "Anytime") {
        prefix = "workexp.company.id:"
      }
      break;
    case 'Industry':
      prefix = "workexp.current.company.cb_categories:";
      if (operator == "Last 3 years") {
        prefix = "workexp.recent.company.cb_categories:"
      } else if (operator == "Anytime") {
        prefix = "workexp.company.cb_categories:";
      }
      break;
    case 'Job Title':
      prefix = "workexp.current.title:";
      if (operator == "Last 3 years") {
        prefix = "workexp.recent.title:"
      } else if (operator == "Anytime") {
        prefix = "workexp.title:";
      }
      break;
    case 'Company Lists':
      prefix = "workexp.current.company.id:";
      if (operator == "Last 3 years") {
        prefix = "workexp.recent.company.id:"
      } else if (operator == "Anytime") {
        prefix = "workexp.company.id:"
      }
      break;
    case 'Skills':
      prefix = "skills:";
      break;
    case 'Company Stage':
    case 'Company Business Model':
      prefix = '';
      break;
    case 'Location':
      prefix = '';
      break;
    default:
      throw "Invalid" + field;
  }

  let query = [];
  if (field == "Company Lists") {
    value?.forEach((cl) => {
      if (cl.type && cl.type == 'macro') {
        query.push(cl.value);
        return;
      }
      cl.companies.forEach((v) => {
        query.push(prefix + v);
      });
    });
  } else {
    value?.forEach((v) => {
      if (v.type && v.type == 'macro') {
        query.push(v.value);
        return;
      }
      query.push(prefix + v.value);
    });
  }
  return '(' + query.join(" OR ") + ')'
}

export async function fetchQueries(uid, joid) {
  let body = {
    only_user: true
  }
  if (cache['queries']) {
    return cache['queries'];
  }
  const response = await fetch(`//search.findem.ai/pub/api/fetch_queries`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }

  const resp = await response.json();
  cache['queries'] = resp;
  return resp;
}

export const purgeCachedCompanyLists = (joid)=> {
  delete cache[`companylists-${joid}`];
}

export async function fetchSimilarCompanies(companies) {
  let body_str = companies.join(',');
  if (cache[body_str]) {
    return cache[body_str];
  }
  const response = await fetch(`//search.findem.ai/pub/api/simcomps?incl_size=true&count=50&comp_ids=${body_str}`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'GET', credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }

  const resp = await response.json();
  cache[body_str] = resp;
  return resp;
}

export async function fetchCompanyOrgDetails(company_id) {
  // check in cache
  if (!cache['orgChart']) {
    cache['orgChart'] = {};
  }
  if (cache['orgChart'][company_id]) {
    return cache['orgChart'][company_id];
  }
  const response = await fetch(`https://matches.findem.ai/hm/api/orgcharts`, {
      headers: new Headers({ 'content-type': 'application/json' }),
      method: 'POST',
      body: JSON.stringify({
        "type": "company_id",
        "data": company_id,
        "sandbox": true
      }),
      credentials: 'include',
      mode: 'cors'
    })
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const resp = await response.json();
  cache['orgChart'][company_id] = resp;
  return resp;
}

export const fetchCompanyRows = async ({ fromIndex, toIndex, sortModel, filterModel, searchTerm }) => {
  console.log("fetchCompanyRows: ", fromIndex, toIndex, sortModel, filterModel)
  const pageSize = (toIndex - fromIndex);
  const page = Math.ceil(fromIndex / pageSize);
  const _filterModel = JSON.parse(JSON.stringify(filterModel));

  const url = new URL('https://pub.findem.ai/pub/api/execmap');
  console.log("fromIndex is ", fromIndex, "toIndex is ", toIndex, "page is ", page, "pageSize is ", pageSize);
  const _items = _filterModel.items;
    // check if any item field is in Object COLUMN_MAPPING and replace it with the actual field name
    _items.forEach((item) => {
      if (item.field in COLUMN_MAPPING) {
        item.field = COLUMN_MAPPING[item.field];
      }
    });
  if(searchTerm && searchTerm.length > 3){
    _filterModel.items.push({
      field: "all_fields",
      operator: "contains",
      value: searchTerm
    });
  }
  // Adapt these parameters to match your API
  const params = {
    type: "companies",
    page: page,
    pageSize: pageSize,
    sort: JSON.stringify(sortModel),
    filter: JSON.stringify(_filterModel),
  };

  const response = await fetch(url, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(params), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  let data = await response.json();
  return data;
};

export const fetchCompaniesById = async (ids) => {
  if (ids.length == 0) {
    return [];
  }
  const url = new URL('https://pub.findem.ai/pub/api/execmap');
  // Adapt these parameters to match your API
  const params = {
    type: "fetchCompaniesById",
    refs: ids
  };

  const response = await fetch(url, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(params), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  let data = await response.json();
  return data;
};

let icps_by_id = null;
export const fetchIcpsById = async (joid) => {
  if (icps_by_id) {
    return icps_by_id;
  }

  let url_str = 'https://matches.findem.ai/hm/api/icpv3';
  if (joid) {
    url_str = url_str + '?joid=' + joid
  }
  const url = new URL(url_str);
  // Adapt these parameters to match your API
  const response = await fetch(url, { headers: new Headers({ 'content-type': 'application/json' }), method: 'GET', credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  let data = await response.json();
  icps_by_id = {};
  data.forEach((d) => {
    icps_by_id[d.icp.ref] = d.icp;
  })
  return data;
};

export const fetchSlCount = (icpid) => {
  // parse and fetch
  if (!icps_by_id) {
    return 0;
  }
  let icp = icps_by_id[icpid];
  if (!icp) {
    return 0;
  }
  let mf = icp?.cxt_data?.match_feedback ? icp?.cxt_data?.match_feedback : [];
  return mf.length;
}

export const getMapBootStr = (queries) => {
  let final_q = [];
  queries.forEach((q) => {
    if (!q.rules) {
      return;
    }
    let inner_q = '';
    const conditions = [];
    const operators = [];
    q.rules.forEach((rule, idx) => {
      if ((idx % 2) == 0) {
        // interest
        if(typeof rule === 'string'){
          return;
        }
        if(rule && rule.field === 'Location'){
          return;
        }
        let field = rule.field;
        let operator = rule.operator;
        let value = rule.value ?? [];
        let aggr_query = buildQuery(field, operator, value || []);
        if(value.length > 0){
          conditions.push(aggr_query);
        }
      } else {
        // check if value present before and after index
        if(q.combinators){
          operators.push(q.combinators[idx] ? q.combinators[idx]?.toUpperCase() : 'AND');
        }else{
          operators.push(!rule || (rule && rule) === 'and' ?' AND' : 'OR');
        }
      }
    })
    inner_q = conditions.reduce((acc, val, idx) => {
      if (idx > 0) {
        return acc + ' ' + operators[idx-1] + ' ' + val;
      }
      return val;
    }, '');
    if(inner_q.trim().length > 0){
      final_q.push(' (' + inner_q + ') ');
    }
  });
  return final_q.join(' OR ').trim();
}

export async function fetchSearchById(searchid, joid) {
  const response = await fetch(`${searchUrl}/pub/api/icp`, {
    headers: new Headers({ 'content-type': 'application/json' }),
    method: 'POST',
    body: JSON.stringify({
      "type": "ByIcpId",
      "icpid": searchid,
      "joid": joid,
      "sync_profile_refresh_status": true
    }),
    credentials: 'include',
    mode: 'cors',
  });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  return await response.json();
}

export async function createUpdateSearchAndLoadLongList(name, icpid = '', queries, joid) {
  const final_q_str = getMapBootStr(queries);
  console.log(final_q_str);
  let reqs = []
  if(icpid && icpid !== ''){
    // get search by icpid
    let search = await fetchSearchById(icpid, joid);
    if(search){
      reqs = search.requirements.filter((req) => req.section_id !== 'map');
    }
  }
  reqs.push({
    is_negative: false,
    req_type: "good to have",
    section_id: "map",
    section_name: "Map",
    section_order: 6,
    section_type: "Must Have",
    bool_str: escape(final_q_str)
  });
  console.log("Final string " + final_q_str);

  let body = {
    "name": name,
    "search_type": 0,
    "description": "",
    "mode": "v2",
    "prioritize_diversity": true,
    "locations": [],
    "joid": joid,
    "type": "CreateUpdateV2",
    "ss_cxt": {
      "sl_options": {
        "exclude_company": true,
        "exclude_prev_company": true,
        "exclude_shortlisted": true,
        "exclude_ats": false,
        "exclude_crm": false,
        "exclude_shortlisted_duration": 0,
        "exclude_ats_duration": 0,
        "exclude_crm_duration": 0
      }
    },
    "requirements": reqs
  }
  if (icpid) {
    body.icpid = icpid;
  }
  body.search_type = 7; // Search type 7 from Map search.
  const response = await fetch(`${searchUrl}/pub/api/icp${joid ? `?joid=${joid}` : ''}`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const resp = await response.json();
  return resp;
}

export async function deleteMap(ids, joid) {
  // make an API
  let body = {
    type: 'delete',
    ids: ids
  }
  if(joid){
    body.joid = joid;
  }
  const response = await fetch(`//pub.findem.ai/pub/api/execmap${joid ? `?joid=${joid}` : ''}`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();
  return data;
}

export async function updateSearchLink(ref, searchid, joid) {
  // make an API
  let body = {
    type: 'update_search_link',
    link: 'https://app-next.findem.ai/searches/' + searchid,
    ref: ref
  }
  if(joid){
    body.joid = joid;
  }
  const response = await fetch(`//pub.findem.ai/pub/api/execmap${joid ? `?joid=${joid}` : ''}`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();
  return data;
}

export function fetchCurrentCompanyAttrList() {
  if (cache['currlists']) {
    return cache['currlists'];
  }
  let q = cache['queries'];
  if (!q) {
    return [];
  }
  let res = [];
  let taxonomy = "Company Lists";
  Object.keys(q).forEach((k)=> {
    let macro = q[k];
    if (
      macro &&
      macro.ui_categories &&
      macro.macro_taxonomy && macro.macro_taxonomy.length > 0 && macro.macro_taxonomy[0] && macro.macro_taxonomy[0].includes(taxonomy) && macro.macro_taxonomy[0].includes(";Current")) {
      res.push({
        label: unescape(macro.name),
        title: unescape(macro.name),
        value: macro.ref,
        raw_value: macro.ref,
        type: 'macro'
      });
    }
  });
  cache['currlists'] = res;
  return res;
}

export function fetchRecentCompanyAttrList() {
  if (cache['currlists']) {
    return cache['currlists'];
  }
  let q = cache['queries'];
  if (!q) {
    return [];
  }
  let res = [];
  let taxonomy = "Company Lists";
  Object.keys(q).forEach((k)=> {
    let macro = q[k];
    if (
      macro &&
      macro.ui_categories &&
      macro.macro_taxonomy && macro.macro_taxonomy.length > 0 && macro.macro_taxonomy[0] && macro.macro_taxonomy[0].includes(taxonomy) && macro.macro_taxonomy[0].includes("Recent")) {
      res.push({
        label: unescape(macro.name),
        title: unescape(macro.name),
        value: macro.ref,
        raw_value: macro.ref,
        type: 'macro'
      });
    }
  });
  cache['currlists'] = res;
  return res;
}

export function fetchMacrosByTaxonomy(input, taxonomy){
  let q = cache['queries'];
  if (!q) {
    return [];
  }
  let res = [];
  Object.keys(q).forEach((k)=> {
    let macro = q[k];
    if (
      macro &&
      macro.macro_taxonomy && macro.macro_taxonomy.length > 0 && macro.macro_taxonomy[0] && macro.macro_taxonomy[0].includes(taxonomy) &&
      (input.trim() === '' || macro.name.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    ) {
      res.push({
        label: unescape(macro.name),
        title: unescape(macro.name),
        value: macro.ref,
        raw_value: macro.ref,
        type: 'macro'
      });
    }
  });
  // check if input is present in the list if not add it
  if(input.trim() !== ''){
    let found = res.find((item) => item.title.toLowerCase() === input.toLowerCase());
    if(!found){
      res.push({
        label: input,
        title: input,
        value: input,
        raw_value: input,
      });
    }
  }
  return res;
}

export function fetchCurrOrPastCompanyAttrList() {
  if (cache['currlists']) {
    return cache['currlists'];
  }
  let q = cache['queries'];
  if (!q) {
    return [];
  }
  let res = [];
  let taxonomy = "Company Lists";
  Object.keys(q).forEach((k)=> {
    let macro = q[k];
    if (
      macro &&
      macro.ui_categories &&
      macro.macro_taxonomy && macro.macro_taxonomy.length > 0 && macro.macro_taxonomy[0] && macro.macro_taxonomy[0].includes(taxonomy) && macro.macro_taxonomy[0].includes("Current or Past")) {
      res.push({
        label: unescape(macro.name),
        title: unescape(macro.name),
        value: macro.ref,
        raw_value: macro.ref,
        type: 'macro'
      });
    }
  });
  cache['currlists'] = res;
  return res;
}

export async function FetchCompanyLists() {
  return [];
}

export async function fetchAllMaps(joid) {
  // make an API
  // return list
  let body = {
    type: 'listall',
    joid: joid
  }
  const response = await fetch(`//pub.findem.ai/pub/api/execmap`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();
  return data;
}

export async function fetchCompanyFields(input, field) {
  if (!field) {
    return [];
  }
  let body = {
    prefix: input || '',
    count: 30,
    field
  };
  let body_str = JSON.stringify(body);
  if (cache[body_str]) {
    return cache[body_str];
  }
  // GET fetch api endpoint-//pub.findem.ai/pub/api/company/autocomplete
  const response = await fetch(
    `//pub.findem.ai/pub/api/company/autocomplete?size=${DEFAULT_SUGGESTION_COUNT}&field=${field}&prefix=${input || ''}`,
    { headers: new Headers({ 'content-type': 'application/json' }),
    method: 'GET',
    credentials: 'include',
    mode: 'cors'
  });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();
  let res = [];
  let dups = [];
  data.suggestions.forEach((item) => {
    if (dups.indexOf(item.text.toLowerCase().trim()) < 0) {
      res.push({
        title: unescape(item.text),
        label: unescape(item.text),
        value: unescape(item.text),
        raw: unescape(item.text),
      });
    }

    dups.push(item.text.toLowerCase().trim());
  });
  if (res.length === 0) {
    res.push({
      title: input,
      label: input,
      value: input,
      raw: input,
    });
  }
  cache[body_str] = sortOptionsByInputMatchIndex(input,res);
  return sortOptionsByInputMatchIndex(input,res);
}

export async function fetchCompanies(input) {
  if (!input) {
    return [];
  }
  let body = {
    prefix: input,
    count: 15
  };
  let body_str = JSON.stringify(body);
  if (cache[body_str]) {
    return cache[body_str];
  }

  const response = await fetch(`//pub.findem.ai/pub/api/company_ac`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();
  let res = [];
  let dups = [];
  data.forEach((item) => {
    if (dups.indexOf(item.domain.toLowerCase().trim()) < 0) {
      res.push({
        title: unescape(item.name) + ' (' + item.domain + ')',
        value: unescape(item.ref),
        raw: unescape(item.name),
        domain: unescape(item.domain)
      });
    }

    dups.push(item.domain.toLowerCase().trim());
  });
  if (res.length === 0) {
    res.push({
      title: input,
      value: input,
      raw: input,
      domain: ''
    });
  }
  const curatedOptions = [
    {
      label: "Standard",
      title: "Standard",
      options: sortOptionsByInputMatchIndex(input,res)
    }
  ]
  cache[body_str] = curatedOptions;
  return curatedOptions;
}

export async function fetchIndustriesFilter(input, operator) {
  let data = await fetchIndustries();
  let starswith = [];
  let contains = [];
  data.forEach((d) => {
    if (d.title.toLowerCase().startsWith(input.toLowerCase()) >= 0) {
      starswith.push({
        title: d.title,
        value: d.value,
        label: d.title
      })
    } else if (d.title.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
      contains.push({
        title: d.title,
        label: d.title,
        value: d.value
      })
    } else if (input.trim() === '') {
      contains.push({
        title: d.title,
        label: d.title,
        value: d.value
      })
    }
  })
  let res = contains.concat(starswith);
  const curatedOptions = [
    {
      label: "Standard",
      title: "Standard",
      options: [...sortOptionsByInputMatchIndex(input,res),
        {
          title: input,
          label: input,
          value: input
        }
      ]
    }
  ]
  return curatedOptions;
}

export async function fetchCategoriesFilter(input, operator) {
  let data = await fetchProductCat();
  let starswith = [];
  let contains = [];
  console.log("fetchCategoriesFilter", input, operator);
  data.forEach((d) => {
    if (d.title.toLowerCase().startsWith(input.toLowerCase()) >= 0) {
      starswith.push({
        title: d.title,
        value: d.value,
        label: d.title
      })
    } else if (d.title.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
      contains.push({
        title: d.title,
        label: d.title,
        value: d.value
      })
    } else if (input.trim() === '') {
      contains.push({
        title: d.title,
        label: d.title,
        value: d.value
      })
    }
  })
  let res = contains.concat(starswith);
  const curatedOptions = [
    {
      label: "Standard",
      title: "Standard",
      options: sortOptionsByInputMatchIndex(input,res)
    }
  ]
  return curatedOptions;
}

export async function fetchCompanyListsFilter(input, operator, joid) {
  let data = await fetchCompanyLists(joid);
  let startsWith = [];
  let contains = [];
  if (!input) {
    return data;
  }
  data.forEach((d) => {
    if (d.title.toLowerCase().startsWith(input.toLowerCase()) >= 0) {
      startsWith.push(d)
    } else if (d.title.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
      contains.push(d)
    }
  })
  let res = contains.concat(startsWith);
  let clist = [];
  console.log("fetchCompanyListsFilter", input, operator);
  if (operator == "Current Experience") {
    clist = fetchCurrentCompanyAttrList();
  } else if (operator == "Last 3 years") {
    clist = fetchRecentCompanyAttrList();
  } else if (operator == "Anytime") {
    clist = fetchCurrOrPastCompanyAttrList();
  }
  console.log("clist", clist);
  startsWith = [];
  contains = [];
  clist.forEach((d)=> {
    if (d.title.toLowerCase().startsWith(input.toLowerCase())) {
      startsWith.push(d)
    }
    // } else if (d.title.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
    //   d.type = 'macro';
    //   contains.push(d)
    // }
  })
  let res2 = contains.concat(startsWith);
  const curatedOptions = [
    {
      label: 'Custom Lists',
      title: 'Custom Lists',
      options: sortOptionsByInputMatchIndex(input,res)
    },
    {
      label: 'Findem Curated Lists',
      title: 'Findem Curated Lists',
      options: sortOptionsByInputMatchIndex(input,res2)
    }
  ];
  console.log("curatedOptions", curatedOptions);
  return curatedOptions;
}

export async function fetchIndustries() {
  if (cache['industry']) {
    return cache['industry'];
  }
  const response = await fetch(`//search.findem.ai/pub/api/industrylist`, { headers: new Headers({ 'content-type': 'application/json' }), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const resp = await response.json();
  let industries = [];
  resp.forEach((item) => {
    if (!item) {
      return;
    }
    industries.push({
      title: item,
      label: item,
      value: item
    });
  });
  cache['industry'] = industries;
  return industries;
}

export async function fetchProductCat() {
  if (cache['pCategories']) {
    return cache['pCategories'];
  }
  const response = await fetch(`//search.findem.ai/pub/api/pcats`, { headers: new Headers({ 'content-type': 'application/json' }), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const resp = await response.json();
  let industries = [];
  resp?.pcat?.forEach((item) => {
    if (!item) {
      return;
    }
    industries.push({
      title: item,
      label: item,
      value: item
    });
  });
  cache['pCategories'] = industries;
  return industries;
}

export async function fetchTitles(input, taxonomy = ';Current', onlyKeywords = false) {
  if (!input) {
    return [];
  }
  let body = {
    types: "T",
    prefix: input,
    count: 15
  };
  let body_str = JSON.stringify(body);
  if (cache[body_str]) {
    return cache[body_str];
  }

  const response = await fetch(`//pub.findem.ai/pub/api/query_suggest`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }

  const data2 = await response.json();
  console.log('data2',!data2, data2);
  if (!data2) {
    return {};
  }
  let data = data2.titles.sort((a, b) => {
    return b?.total_count - a?.total_count
  });

  // data = data.sort((a, b) => {
  //   return b.current - a.current
  // });
  // is input present in data or not check

  let res = [];
  for (let it = 0; it < 5; it++) {
    let title = data[it];
    if (!title) {
      continue;
    }
    if (title.current === 0) {
      continue;
    }
    let text = unescape(title.title);
    res.push({
      label: text,
      title: text,
      value: text,
      key: text,
      raw_value: 'workexp.current.title:' + unescape(title.title),
    });
  }

  const idx = res.findIndex((item) => item.title.toLowerCase() === input.toLowerCase());
  if(data.length === 0 || idx === -1){
    res.push({
      label: input,
      title: input,
      value: input,
      key: input,
      raw_value: 'workexp.current.title:' + input,
    });
  }

  const _titles = titleAttributesByUiCat(input, taxonomy);
  const _curatedOptions = [
    {
      label: 'Findem Curated',
      title: 'Findem Curated',
      options: _titles
    },
    {
      label: 'Standard',
      title: 'Standard',
      options: sortOptionsByInputMatchIndex(input, res)
    }
  ]
  cache[body_str] = _curatedOptions;
  return onlyKeywords ? res : _curatedOptions;
}

export async function runMatchCount(requirements, to_match) {
  let body = {
    requirements: requirements,
    to_match_requirements: to_match,
    to_match: []
  }
  if (cache[JSON.stringify(body)]) {
    return cache[JSON.stringify(body)];
  }

  const response = await fetch(`${searchUrl}/pub/api/multi_matchesV2`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const resp = await response.json();
  cache[JSON.stringify(body)] = resp;
  return resp;
}

export async function createCompanyList(name, companies, joid) {
  let body = {
    type: 'create_company_list',
    name: name,
    companies: companies
  }
  const response = await fetch(`//pub.findem.ai/pub/api/execmap`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();

  const index = cache[`companylists-${joid}`]?.findIndex(item => item.value === data.ref) ?? -1;
  if (index !== -1) {
    // Replace the old item with the new one
    cache[`companylists-${joid}`].splice(index, 1, {
      title: data.name,
      value: data.ref,
      created_at: data.created_at,
      companies: data.companies
    });
  } else if(cache[`companylists-${joid}`]){
    // Item not found, so let's add it to the array
    cache[`companylists-${joid}`].push({
      title: data.name,
      value: data.ref,
      created_at: data.created_at,
      companies: data.companies
    });
  }
  return data;
}

export async function updateCompanyList(ref, name, companies, joid) {
  let body = {
    type: 'update_company_list',
    name: name,
    companies: companies,
    ref: ref
  }
  const response = await fetch(`//pub.findem.ai/pub/api/execmap`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();

  const index = cache[`companylists-${joid}`]?.findIndex(item => item.value === data.ref);
  if (index !== -1) {
    // Replace the old item with the new one
    cache[`companylists-${joid}`].splice(index, 1, {
      title: data.name,
      value: data.ref,
      created_at: data.created_at,
      companies: data.companies
    });
  } else if(cache[`companylists-${joid}`]){
    // Item not found, so let's add it to the array
    cache[`companylists-${joid}`].push({
      title: data.name,
      value: data.ref,
      created_at: data.created_at,
      companies: data.companies
    });
  }
  return data;
}

export async function deleteCompanyList(ids) {
  let body = {
    type: 'remove_company_list',
    ids: ids
    // type: 'update_company_list',
    // type: 'remove_company_list'
  }
  const response = await fetch(`//pub.findem.ai/pub/api/execmap`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();
  return data;
}

export async function fetchCompanyLists(joid) {
  if (cache[`companylists-${joid}`]) {
    return cache[`companylists-${joid}`];
  }

  let body = {
    type: 'fetch_all_company_lists',
  }
  if(joid){
    body.joid = joid;
  }
  const response = await fetch(`//pub.findem.ai/pub/api/execmap${joid ? '?joid='+joid : ''}`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const resp = await response.json();
  let clists = [];
  resp.forEach((item) => {
    if (!item) {
      return;
    }
    clists.push({
      title: item.name,
      value: item.ref,
      created_at: item.created_at,
      companies: item.companies
    });
  });

  cache[`companylists-${joid}`] = clists;
  return clists;
}

export async function fetchSkills(input) {
  if (!input) {
    return [];
  }
  let body = {
    type: 'SearchProfileSkills',
    prefix: input,
    count: 15
  };
  let body_str = JSON.stringify(body);
  if (cache[body_str]) {
    return cache[body_str];
  }

  const response = await fetch(`//pub.findem.ai/pub/api/profile-skills`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }

  const data = await response.json();
  let res = [];
  for (let it = 0; it < 10; it++) {
    let item = data[it];
    if (!item) {
      continue;
    }
    let text = unescape(item.skill);
    res.push({
      label: text,
      value: unescape(text),
      key: text,
      raw_value: 'skills:' + unescape(text)
    });
  }
  if(res.length === 0){
    res.push({
      label: input,
      value: input,
      key: input,
      raw_value: 'skills:' + input
    });
  }
  const _titles = attributesByUiCat(input, "All Skills");
  const _curatedOptions = [
    {
      label: 'Findem Curated',
      options: _titles
    },
    {
      label: 'Standard',
      options: sortOptionsByInputMatchIndex(input,res)
    }
  ]
  cache[body_str] = _curatedOptions;
  return _curatedOptions;
}

function attributesByUiCat(input, uicat, taxonomy){
  if (!cache['queries']) {
    return;
  }
  let q = cache['queries'];
  let res = [];
  Object.keys(q).forEach((key) => {
    const macro = q[key];
    if (
      macro &&
      macro.ui_categories && uicat &&
      (macro.ui_categories.indexOf(uicat) >= 0) &&
      (!taxonomy || (
        macro.macro_taxonomy && macro.macro_taxonomy.length > 0 && taxonomy && macro.macro_taxonomy[0] && macro.macro_taxonomy[0].includes(taxonomy)))
    ) {
      if (input.trim() === '' || macro.name.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
        res.push({
          label: unescape(macro.name) + `${macro?.categories && macro.categories.includes("Verified Skills") ? "(Verified)" : ""}`,
          value: macro.ref,
          key: macro.ref,
          type: 'macro',
          raw_value: macro.ref
        });
      }
    }
  });
  return sortOptionsByInputMatchIndex(input,res).slice(0, 10);
}

function titleAttributesByUiCat(input, taxonomy){
  if (!cache['queries']) {
    return;
  }
  let q = cache['queries'];
  let res = [];
  Object.keys(q).forEach((key) => {
    const macro = q[key];
    if (
      macro.macro_taxonomy &&
      macro.macro_taxonomy.length > 0 &&
      macro.macro_taxonomy[0] &&
      macro.macro_taxonomy[0].startsWith('Role;') &&
      (!taxonomy || (
        macro.macro_taxonomy && macro.macro_taxonomy.length > 0 && taxonomy && macro.macro_taxonomy[0] && macro.macro_taxonomy[0].includes(taxonomy)))
    ) {
      if (input.trim() === '' || macro.name.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
        res.push({
          label: unescape(macro.name) + `${macro?.categories && macro.categories.includes("Verified Skills") ? "(Verified)" : ""}`,
          value: macro.ref,
          key: macro.ref,
          type: 'macro',
          raw_value: macro.ref
        });
      }
    }
  });
  return sortOptionsByInputMatchIndex(input, res).slice(0, 10);
}

export async function prefixQueries(input) {
  if (!cache['queries']) {
    return;
  }
  let q = cache['queries'];
  let res = []
  Object.keys(q).forEach((key) => {
    let macro = q[key];
    if (macro.name.indexOf("_Internal") >= 0) {
      return;
    }
    let prefix = '';
    if (macro?.macro_taxonomy && macro?.macro_taxonomy.length &&  macro.macro_taxonomy[0]) {
      let tax = macro.macro_taxonomy[0].split(';');
      prefix = (tax.length > 1 ? tax.join(' > ') : tax.join(''));
    }
    if (macro.name.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
      res.push({
        label: ((prefix.indexOf(" > ") >= 0)? (prefix + ' > ' + unescape(macro.name)) : unescape(macro.name)),
        value: macro.ref,
        key: macro.ref,
        raw_value: macro.ref
      });
    }
  });
  return res.length > 0 ? res.slice(0, 10) : [];
}

export function fetchIcpById(icpid) {
  return icps_by_id[icpid];
}

export async function prefixSearch(input) {
  if (!icps_by_id) {
    return;
  }
  let q = icps_by_id;
  let res = []
  Object.keys(q).forEach((key) => {
    let icp = q[key];
    if (icp.name.indexOf("_Temp") >= 0) {
      return;
    }
    if (icp.name.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
      res.push({
        label: unescape(icp.name),
        value: icp.ref,
        key: icp.ref,
        raw_value: icp.ref,
        updated_at: icp.updated_at
      });
    }
  });
  res.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
  return res.length > 0 ? res : [];
}

export async function createOrUpdateDynamicColumn(ref, type, value, name) {
  let body = {
    req_type: type,
    ref: ref,
    name: name,
    value: value,
    type: 'create_or_update_dynamic_column'
  }
  const response = await fetch(`//pub.findem.ai/pub/api/execmap`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();

  const index = cache['dyncols'].findIndex(item => item.ref === data.ref);
  if (index !== -1) {
    // Replace the old item with the new one
    cache['dyncols'].splice(index, 1, data);
  } else {
    // Item not found, so let's add it to the array
    cache['dyncols'].push(data);
  }
  return data;
}

export async function fetchDynamicColumn(ref) {
  let body = {
    type: 'fetch_dynamic_columns',
    ref: ref
  }
  const response = await fetch(`//pub.findem.ai/pub/api/execmap`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();

  const index = cache['dyncols'].findIndex(item => item.ref === data.ref);
  if (index !== -1) {
    // Replace the old item with the new one
    cache['dyncols'].splice(index, 1, data);
  } else {
    // Item not found, so let's add it to the array
    cache['dyncols'].push(data);
  }
  return data;
}

export async function deleteDynamicColumns(ids) {
  let body = {
    type: 'delete_dynamic_columns',
    refs: ids
  }
  const response = await fetch(`//pub.findem.ai/pub/api/execmap`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  ids.forEach((id)=> {
    const index = cache['dyncols'].findIndex(item => item.ref === id);
    if (index !== -1) {
      // Replace the old item with the new one
      cache['dyncols'].splice(index, 1);
    }
  });
}

export async function getLocationsByTerm(locationService, locationInput, setLocationOptions, currentSelectedLocations) {
  await locationService?.getPlacePredictions(
    {
      input: locationInput,
    },
    (result) => {
      const _options =  [];
      const options = result
        ? result.map((result) => {
            if (!result?.types?.includes('establishment')) {
              return ({
                label: result.description,
                value: result.place_id,
              });
            }
          }).filter((loc) => loc) : [];
        // fetch all the places
        locationService?.getPlacePredictions(
          {
            input: locationInput,
            types: ['(regions)'],
          },
          (result2) => {
            const resultDesc = result.map((result) => result.description);
            const options2 = result2
              ? result2.map((result2) => {
                  if (!result2?.types?.includes('establishment') && !resultDesc.includes(result2.description)) {
                    return ({
                      label: result2.description,
                      value: result2.place_id,
                    });
                  }
                }).filter((loc) => loc) : [];
            // console.log('result2 options', options, options2, currentSelectedLocations);
            _options.push({
              label: 'Locations',
              options: options.concat(options2).filter((loc) => currentSelectedLocations.length
              ? !currentSelectedLocations.includes(loc.value ?? '')
              : true
            ),
            })
            console.log('setLocationOptions', _options);
            setLocationOptions(_options);
          }
        );
    }
  );
}

export async function listAllDynamicColumns() {
  if (cache['dyncols']) {
    return cache['dyncols'];
  }

  let body = {
    type: 'list_all_dynamic_columns'
  }
  const response = await fetch(`//pub.findem.ai/pub/api/execmap`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const resp = await response.json();
  // let cols = [];
  // resp.forEach((item) => {
  //   if (!item) {
  //     return;
  //   }
  //   cols.push({
  //     title: item.name,
  //     value: item.value,
  //     ref: item.ref,
  //     created_at: item.created_at,
  //     type: item.type
  //   });
  // });

  cache['dyncols'] = resp;
  return resp;
}

export async function createCompanyCompMacros (company_ids, uid, joid, name, description, regencies) {
  let mids = company_ids.map(b => { return "workexp.current.company.id:" + b });
  let mids_recent = company_ids.map(b => { return "workexp.recent.company.id:" + b });
  let mids_past = company_ids.map(b => { return "workexp.past.company.id:" + b });
  let mids_past_or_current = company_ids.map(b => { return "workexp.company.id:" + b });
  let body = {
    "joid": joid,
    "negs": [],
    "force_name": true,
    "macroids": [],
    "keywords": mids,
    "op": "or",
    "categories": [
        "Custom List"
    ],
    "ui_categories": [
        "Saved"
    ],
    "macro_taxonomy": [
        "My Data;My Lists;Company Lists;Current Company Lists"
    ],
    "description": '',
    "name": name,
    "is_org_public": false
  }

  let body_recent = {
    "joid": joid,
    "negs": [],
    "force_name": true,
    "macroids": [],
    "keywords": mids_recent,
    "op": "or",
    "categories": [
        "Custom List"
    ],
    "ui_categories": [
        "Saved"
    ],
    "macro_taxonomy": [
        "My Data;My Lists;Company Lists;Recent Company Lists"
    ],
    "description": '',
    "name": name+" (Recent)",
    "is_org_public": false
  }

  let body_past = {
    "joid": joid,
    "negs": [],
    "force_name": true,
    "macroids": [],
    "keywords": mids_past,
    "op": "or",
    "categories": [
        "Custom List"
    ],
    "ui_categories": [
        "Saved"
    ],
    "macro_taxonomy": [
        "My Data;My Lists;Company Lists;Past Company Lists"
    ],
    "description": '',
    "name": name+" (Past)",
    "is_org_public": false
  }

  let body_past_or_current = {
    "joid": joid,
    "negs": [],
    "force_name": true,
    "macroids": [],
    "keywords": mids_past_or_current,
    "op": "or",
    "categories": [
        "Custom List"
    ],
    "ui_categories": [
        "Saved"
    ],
    "macro_taxonomy": [
        "My Data;My Lists;Company Lists;Past or Current Company Lists"
    ],
    "description": '',
    "name": name+" (Past or Current)",
    "is_org_public": false
  }
  const apis = [];
  if(regencies.includes(RegencyType.current)) {
    apis.push(createMacro(body));
  }
  if(regencies.includes(RegencyType.recent)) {
    apis.push(createMacro(body_recent));
  }
  if(regencies.includes(RegencyType.past)) {
    apis.push(createMacro(body_past));
  }
  if(regencies.includes(RegencyType.past_and_current)) {
    apis.push(createMacro(body_past_or_current));
  }
  return Promise.all(apis);
}

export async function createMacro(body) {
  return fetch(`${searchUrl}/pub/api/create_compound_macro`, { headers: new Headers({ 'content-type': 'application/json' }), method: 'POST', body: JSON.stringify(body), credentials: 'include', mode: 'cors' });
}